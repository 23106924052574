.ingredient-edit .quantity {
    width: 6rem;
}

.ingredient-edit .unit,
.ingredient-edit .ingredient-select {
    flex-grow: 1;
}

.MuiButtonBase-root.error {
    background-color: var(--error);
    color: var(--error-text);
}