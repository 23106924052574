.recipe {
	margin: 1rem auto;
	height: calc(100% - 2em);
	box-sizing: border-box;
	position: relative;
	display: flex;
	flex-direction: column;
	max-width: 1200px;

	.MuiCardHeader-root {
		color: white;
		background-color: #66a832;
		height: fit-content;

		.MuiTypography-root {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			font-weight: normal;
			font-size: 1.1rem;
		}		

		.MuiCardHeader-title {
			float: left;
			width: auto;
		}

		.MuiCardHeader-subheader {
			float: left;
			margin-left: 1rem;
			font-size: 0.8rem;
			top: 1px;
			position: relative;
		}

		.MuiCardHeader-action {
			margin: 0;

			.MuiButtonBase-root {
				color: white;
				padding: 6px;
				margin: 0 6px;
			}	
		}
	}

	.body {
		display: flex;
		height: 100%;
		overflow: hidden;
	}

	.media {
		flex: 1;
		position: relative;

		.servings-wrapper {
			position: absolute;
			bottom: 0;
			padding: 1rem;
			display: flex;
			justify-content: center;
			width: 100%;

			.servings {
				background-color: rgba(255,255,255,0.6);
				border-radius: 2rem;
				padding: 0.5rem 1rem;
				color: #666666;
				width: fit-content;
				display: flex;
				gap: 0.5em;
				align-items: baseline;
			}
		}

		.MuiCardMedia-root {
			box-sizing: border-box;
			vertical-align: top;
			height: 100%;
			width: 100%;
		}
	}

	.content {
		flex: 2;
		box-sizing: border-box;
		height: 100%;

		> .MuiGrid-root {
			height: calc(100% + 32px);
			padding: 16px;

			> .MuiGrid-root {
				padding-top: 0;
				padding-bottom: 0;
				padding-right: 8px;
				height: 100%;
				overflow: auto;
			}
		}
		
		.ingredients, .steps { 
			display: inline-block;
			box-sizing: border-box;
			vertical-align: top;
			padding-right: 8px;
		}
	}
}

@media only screen and (max-width: 800px) {
	.recipe {
		height: auto;
		overflow: auto;
		margin: 0;

		.body {
			flex-direction: column;
		}

		.media {
			flex: 1;

			.MuiCardMedia-root {
				width: 100%;
				height: 250px;
				overflow: auto;
				flex-basis: 100%;
			}
		}

		.content {
			flex: 1;
			box-sizing: border-box;
			height: calc(100% - 150px - 3rem);
			overflow-y: auto;
			overflow-x: hidden;
			flex-basis: 100%;

			> .MuiGrid-root {
				gap: 16px;

				> .MuiGrid-root {
					overflow: visible;
					flex-basis: 100%;
					max-width: 100%;
				}
			}

			.ingredients, .steps {
				width: 100%;
				height: auto;
			}
		}
	}
}

@media print {
	.viewport {
		top: 0;
		margin: 0;
		height: 100%;
	}

	.recipe {
		top: 0;
		margin: 0;
		height: 100%;
	
		&.MuiPaper-rounded {
			border-radius: 0;
		}
	
		&.MuiCard-root {
			box-shadow: none;
			overflow: visible;
			flex-direction: column;
		}

		.source {
			display: none;
		}

		.MuiCardHeader-root {
			.MuiTypography-h5 {
				font-size: 18px;
			}

			.MuiCardHeader-action {
				display: none;
			}
		}

		.body {
			height: 100%;
		}

		.media {
			.MuiCardMedia-root {
				height: 100%;
			}
		}

		.content {
			padding: 0;
			padding-top: 16px;
			padding-left: 16px;
			height: fit-content;

			>.MuiGrid-root {
				height: fit-content;

				>.MuiGrid-root {
					overflow: visible;
				}

				> .MuiGrid-item {
					height: fit-content;
				}
			}

			.MuiButton-root {
				display: none;
			}
		
			.ingredients, .steps {
				height: auto;
			}
		}
	}
}
