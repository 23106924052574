.step {
	line-height: 1.5em;
	position: relative;

	.drag-handle {
		position:absolute;
		left: -3rem;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&.editable {
		margin-left: 3em;
	}

	&.editable:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}