.unit-dialog .MuiDialogContent-root {
    display: flex;
}

.unit-dialog .name {
    flex-grow: 1;
    width: auto;
}

.unit-dialog .symbol {
    width: 6rem;
}

.unit-dialog .size {
    width: 100%;
    display: flex;
}

.unit-dialog .size .MuiSwitch-root {
    margin-top: 15px;
}

.unit-dialog .size .MuiTextField-root {
    flex-grow: 1;
}