:root {
	--error:  #b71c1c;
	--error-text: #ffffff;
}

::-webkit-scrollbar {
	width: 10px;
	background-color: transparent;
}
 
::-webkit-scrollbar-thumb {
	background-color: #66a832;
	border-radius: 5px;
}

body {
	margin: 0;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	.MuiButton-root {
		color: inherit;
	}
}
  
a {
	color: inherit;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
	monospace;
}

.MuiDialog-container {
	.MuiDialog-paper {
		width: 100%;
	}

	.MuiDialogContent-root {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
	}
}

.mobileOnly {
	display: none;
}

.viewport {
	position: fixed;
	width: 100%;
	height: calc(100% - 160px - 64px - 3rem);
	box-sizing: border-box;
	padding: 0.5rem;
	padding-bottom: 0;
	top: 64px;
}

@media only screen and (max-width: 800px) {
	.mobileOnly {
		display: initial;
	}

	.viewport {
		padding: 0;
		margin-top: -7px;
		height: calc(100% - 248px - 2rem);
		overflow: auto;
	}
	
	.drawerClosed {
		.viewport {
			height: calc(100% - 46px - 2rem);
		}
	}
}

@media print {
	@page {
		size: A4 landscape;
	}

	.viewport {
		padding: 0;
		font-size: 10px;
		height: auto;
		top: 0;
	}

	a {
		text-decoration: none;
	}
}
