.thumbmenu {
	position: fixed;
	bottom: 0;
	margin: 0;
	padding: 0 0px 0.5rem 0.5rem;
	list-style: none;
	white-space: nowrap;
	overflow-x: auto;
	width: calc(100% - 2rem);
	background-color: white;

	.grip {
		display: none;
	}

	.MuiCard-root {
		width: 160px;
		height: 160px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		box-sizing: border-box;
		display: inline-block;
		vertical-align: bottom;
		cursor: pointer;
		white-space: normal;
		position: relative;
		margin: 0.5rem 1rem;

		.MuiCardMedia-root {
			height: calc(100% - 1.8rem);
		}

		.MuiCardContent-root {
			padding: 0;

			h2 {
				color: white;
				background-color: #66a832;
				font-size: 0.8rem;
				min-width: 100%;
				margin: 0;
				padding: 0.5rem;
				white-space: nowrap;
				font-weight: normal;
				position: absolute;
				bottom: 0;
				text-overflow: ellipsis;
				overflow: hidden;
				width: 100%;
				box-sizing: border-box;
			}
		}
	}
}

@media only screen and (max-width: 800px) {
	.thumbmenu {
		.grip {
			display: block;
			width: 100%;
			position: fixed;
			margin-top: -1.5rem;
			left: 0;
			height: 1.5rem;
			background-color: #66a832;
			text-align: center;
			color: white;
			cursor: pointer;
		}
	}

	.drawerClosed {
		.viewport {
			height: calc(100% - 56px - 2rem);
		}

		.thumbmenu {
			width: 100%;
			height: 0;

			.grip {
				margin-top: calc(-1.5rem + 10px);
			}
		}
	}
}

@media print {
	.thumbmenu {
		display: none;
	}
}