.ingredient-group {
	list-style: none;
}

.group {
	background-color: #f5f9f1;
	border: 1px solid #66a832;
	border-radius: 5px;
	padding: 1rem;
	margin: 1rem 0 1rem 0;

	h2 {
		color: #66a832;
		margin: 0 0 1rem 0;
		padding: 0;
		font-size: 1rem;
		display: flex;
		gap: 0.5em;
		align-items:center;

		> div {
			line-height: 0;
		}

		> span:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}

	ul {
		list-style: disc;
		list-style-position: inside;
	}

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.default-group {
	list-style: none;
}

@media print {
	.group {
		padding: 8px;
		
		h2 {
			font-size: 12px;
			margin-bottom: 0.5em;

			> div {
				display: none;
			}
		}
	}
}