.image-selector {
	text-align: center;

    > button {
        display: block;
        margin: 0.5rem auto;
    }    

    img {
        display: block;
    }

	.MuiPaper-root.image {       
		position: relative;
		overflow: hidden;

        &.empty {
            width: 100%;
        }

		.clicker {
			cursor: pointer;
			background: transparent;
			border: none;
			min-height: 50px;
			padding: 0;

			img {
				width: 100%;
				border-radius: 4px;
			}
		}
		
		label {
			position: absolute;
			top:0; bottom:0; left: 0;
			margin: auto;
			width: 100%;
			height: 1rem;
			text-align: center;
			transform: none;
			cursor: pointer;
		}
	}

    input[type=file] {
        display: none;
    }

	.uploading {
		position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #FFFFFF99;
			font-weight: 500;
	}
}