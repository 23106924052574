.MuiAppBar-root {
    .MuiToolbar-root {

        .MuiIconButton-root {
            color: inherit;
        }

        .search {
            color: white;

            input {
                padding: 12px;
                color: black;
                font-size: 12px;
            }

            fieldset {
                background-color: rgba(255, 255, 255, 0.25);
                border: 1px solid rgba(0, 0, 0, 0.23);
                border-radius: 4px;

                &:hover {
                    border: 1px solid rgba(0, 0, 0, 0.23) !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .title {
        display: none;
    }
}

@media print {
    .MuiAppBar-root {
        display: none !important;
    }
}