.ingredient {
	padding: 0;
	margin-bottom: 1em;
	line-height: 1.5em;
	list-style: none;
	display: flex;
	gap: 0.5em;

	&:last-child {
		margin-bottom: 0;
	}

	&.editable > span {
		cursor: pointer;

		&:hover {
			text-decoration-line: underline;
		}
	}

	&:not(.editable) {
		svg {
			display: none;
		}
	}
}

@media print {
	.ingredient svg {
		display: none;
	}
}